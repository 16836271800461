import React, { Component } from 'react';

export default class Error extends Component {
    render(){
        return(
            
            <header className="App-header">
            <h1>Ups.</h1>
            <p>La página a la que intenta acceder no ha sido encontrada.</p>
          </header>
        );
    }
}
